export enum CooperationStatus {
  OPENING = 'opening',
  SOLVED = 'solved'
}

export interface Cooperation {
  id: number
  subject: string
  name: string
  email: string
  phone: string
  message: string
  attchment: string
  status: CooperationStatus
}

export type PartialCooperation = Partial<Cooperation>;
